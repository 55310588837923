<div class="page-title-area item-bg-two ripple-effect jquery-ripples">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h1>{{ 'SECTION-BLOG.blog-detail-1-page-title-h1' | translate }}</h1>
                    <ul>
                        <li><a routerLink="/">{{ 'SECTION-BLOG.blog-detail-1-page-title-ul-li-a' | translate }}</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li class="active">{{ 'SECTION-BLOG.blog-detail-1-page-title-ul-li-active' | translate }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row" *ngIf="id==1">
            <div class="col-lg-8 col-md-12 offset-lg-2">
                <div class="blog-details">
                    <div class="article-img">
                        <img src="assets/img/blog-details.jpg" alt="blog-details">
                        <!-- <div class="date">20 <br> Dec</div> -->
                    </div>
                    
                    <div class="article-text">
                        <ul class="category">
                            <li><a href="#">{{ 'SECTION-BLOG.blog-detail-1-article-text-category-ul-li-a-1' | translate }}</a></li>
                            <li><a href="#">{{ 'SECTION-BLOG.blog-detail-1-article-text-category-ul-li-a-2' | translate }}</a></li>
                            <li><a href="#">{{ 'SECTION-BLOG.blog-detail-1-article-text-category-ul-li-a-3' | translate }}</a></li>
                            <li><a href="#">{{ 'SECTION-BLOG.blog-detail-1-article-text-category-ul-li-a-4' | translate }}</a></li>
                            <li><a href="#">{{ 'SECTION-BLOG.blog-detail-1-article-text-category-ul-li-a-5' | translate }}</a></li>
                        </ul>
                           
                        <h4 class="title">{{ 'SECTION-BLOG.blog-detail-1-article-text-title-h4' | translate }}</h4>
                        
                        <p>{{ 'SECTION-BLOG.blog-detail-1-article-text-title-p-1' | translate }}</p>
                        
                        <p>{{ 'SECTION-BLOG.blog-detail-1-article-text-title-p-2' | translate }}</p>
                        
                        <p>{{ 'SECTION-BLOG.blog-detail-1-article-text-title-p-3' | translate }}</p>
                        
                        <blockquote class="blockquote">
                            <p class="mb-0">{{ 'SECTION-BLOG.blog-detail-1-article-text-title-blockquote' | translate }}</p>
                        </blockquote>
                        
                        <p class="mb-0">{{ 'SECTION-BLOG.blog-detail-1-article-text-title-p-4' | translate }}</p>
                        
                        <div class="author-share">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="article-author">
                                        <div class="author-img">
                                            <img src="assets/img/client-avatar1.jpg" alt="client">
                                        </div>

                                        <div class="author-title">
                                            <h4>{{ 'SECTION-BLOG.blog-detail-1-article-author-title-h4' | translate }}</h4>
                                            <span>{{ 'SECTION-BLOG.blog-detail-1-article-author-title-span' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-lg-6 col-md-6">
                                    <ul class="social-share">
                                        <li><a href="#" data-toggle="tooltip" data-placement="top" title="Share on Facebook"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#" data-toggle="tooltip" data-placement="top" title="Share on Twitter"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="#" data-toggle="tooltip" data-placement="top" title="Share on Linkedin"><i class="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="#" data-toggle="tooltip" data-placement="top" title="Share on Instagram"><i class="fab fa-instagram"></i></a></li>
                                        <li><a href="#" data-toggle="tooltip" data-placement="top" title="Share on Vimeo"><i class="fab fa-vimeo-v"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="post-controls-buttons">
                    <div class="controls-left">
                        <a href="#"><i class="fas fa-chevron-left"></i> Prev Post</a>
                    </div>

                    <div class="controls-right">
                        <a href="#">Next Post <i class="fas fa-chevron-right"></i></a>
                    </div>
                </div>
                
                <!-- <div class="post-comments">
                    <h3 class="title mt-0">Comments</h3>
                    <div class="single-comment">
                        <div class="comment-img">
                            <img src="assets/img/client-avatar2.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>David Warner</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <span>Dec 19, 2018 - 9:10AM</span>
                            <a href="#">Reply</a>
                        </div>
                    </div>
                    
                    <div class="single-comment left-m">
                        <div class="comment-img">
                            <img src="assets/img/client-avatar1.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>Eva Amith</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <span>Dec 19, 2018 - 9:10AM</span>
                            <a href="#">Reply</a>
                        </div>
                    </div>
                    
                    <div class="single-comment">
                        <div class="comment-img">
                            <img src="assets/img/client-avatar3.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>James Andy</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <span>Dec 19, 2018 - 9:10AM</span>
                            <a href="#">Reply</a>
                        </div>
                    </div>
                    
                    <div class="single-comment mb-0">
                        <div class="comment-img">
                            <img src="assets/img/client-avatar2.jpg" alt="client">
                        </div>
                        <div class="comment-content">
                            <h4>Audy Kanna</h4>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Et minus, saepe porro a voluptatem, quidem aut libero consequatur unde molestiae quae impedit accusantium dolor est corporis! Dolores ut dignissimos doloribus?</p>
                            <span>Dec 19, 2018 - 9:10AM</span>
                            <a href="#">Reply</a>
                        </div>
                    </div>
                </div> -->
                
                <div class="leave-a-reply">
                    <h3 class="title mt-0">{{ 'SECTION-BLOG.blog-detail-1-leave-a-reply-h3' | translate }}</h3>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" name="name" [placeholder]="'SECTION-BLOG.blog-detail-1-leave-a-reply-input-name-placeholder' | translate">
                            </div>
                        </div>
                        
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="email" class="form-control" name="email" [placeholder]="'SECTION-BLOG.blog-detail-1-leave-a-reply-input-email-placeholder' | translate">
                            </div>
                        </div>
                        
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="comment" cols="30" rows="5" class="form-control" [placeholder]="'SECTION-BLOG.blog-detail-1-leave-a-reply-input-comment-placeholder' | translate"></textarea>
                            </div>
                        </div>
                        
                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="btn btn-primary">{{'SECTION-BLOG.blog-detail-1-leave-a-reply-btn-submit' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>