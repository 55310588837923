<section id="pricing" class="pricing-area pt-100 pb-70 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2 [innerHTML]="'SECTION-PRICING.pricing-title-section-heading-h2' | translate"></h2>
            <p>{{ 'SECTION-PRICING.pricing-title-section-p' | translate }}</p>
        </div>
       
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">{{ 'SECTION-PRICING.pricing-table-free-title-h3' | translate }}</h3>
                    <div class="price-value">
                        <span class="currency">{{ 'SECTION-PRICING.pricing-table-free-currency' | translate }}</span>
                        <span class="amount">{{ 'SECTION-PRICING.pricing-table-free-amount' | translate }}</span>
                        <span class="month">{{ 'SECTION-PRICING.pricing-table-free-month' | translate }}</span>
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-free-content-ul-li-1' | translate }}</li>
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-free-content-ul-li-2' | translate }}</li>
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-free-content-ul-li-3' | translate }}</li>
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-free-content-ul-li-4' | translate }}</li>
                        <li><i class="fas fa-check"></i>{{ 'SECTION-PRICING.pricing-table-free-content-ul-li-5' | translate }}</li>
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-free-content-ul-li-6' | translate }}</li>
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-free-content-ul-li-7' | translate }}</li>
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-free-content-ul-li-8' | translate }}</li>
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-free-content-ul-li-9' | translate }}</li>
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-free-content-ul-li-10' | translate }}</li>
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-free-content-ul-li-11' | translate }}</li>

                    </ul>
                    <a href="#" class="btn btn-primary">{{ 'SECTION-PRICING.pricing-table-free-content-btn' | translate }}</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">{{ 'SECTION-PRICING.pricing-table-basic-title-h3' | translate }}</h3>
                    <div class="price-value">
                        <span class="currency">{{ 'SECTION-PRICING.pricing-table-basic-currency' | translate }}</span>
                        <span class="amount">{{ 'SECTION-PRICING.pricing-table-basic-amount' | translate }}</span>
                        <span class="month">{{ 'SECTION-PRICING.pricing-table-basic-month' | translate }}</span>
                    </div>
                    <ul class="pricing-content">
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-basic-content-ul-li-1' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-basic-content-ul-li-2' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-basic-content-ul-li-3' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-basic-content-ul-li-4' | translate }}</li>
                                    <li><i class="fas fa-check"></i>{{ 'SECTION-PRICING.pricing-table-basic-content-ul-li-5' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-basic-content-ul-li-6' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-basic-content-ul-li-7' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-basic-content-ul-li-8' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-basic-content-ul-li-9' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-basic-content-ul-li-10' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-basic-content-ul-li-11' | translate }}</li>

                    </ul>
                    <a href="#" class="btn btn-primary">{{ 'SECTION-PRICING.pricing-table-basic-content-btn' | translate }}</a>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="pricingTable">
                    <h3 class="title">{{ 'SECTION-PRICING.pricing-table-premium-title-h3' | translate }}</h3>
                    <div class="price-value">
                        <span class="currency">{{ 'SECTION-PRICING.pricing-table-premium-currency' | translate }}</span>
                        <span class="amount">{{ 'SECTION-PRICING.pricing-table-premium-amount' | translate }}</span>
                        <span class="month">{{ 'SECTION-PRICING.pricing-table-premium-month' | translate }}</span>
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-premium-content-ul-li-1' | translate }}</li>
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-premium-content-ul-li-2' | translate }}</li>
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-premium-content-ul-li-3' | translate }}</li>
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-premium-content-ul-li-4' | translate }}</li>
                        <li><i class="fas fa-check"></i>{{ 'SECTION-PRICING.pricing-table-premium-content-ul-li-5' | translate }}</li>
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-premium-content-ul-li-6' | translate }}</li>
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-premium-content-ul-li-7' | translate }}</li>
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-premium-content-ul-li-8' | translate }}</li>
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-premium-content-ul-li-9' | translate }}</li>
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-premium-content-ul-li-10' | translate }}</li>
                        <li><i class="fas fa-check"></i> {{ 'SECTION-PRICING.pricing-table-premium-content-ul-li-11' | translate }}</li>
                    </ul>
                    <a href="#" class="btn btn-primary">{{ 'SECTION-PRICING.pricing-table-premium-content-btn' | translate }}</a>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="image" loading="lazy"></div>
</section>