<section id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h4>{{ 'CONTACT-SECTION.contact-section-heading-h4' | translate }}</h4>
            <h2 [innerHTML]="'CONTACT-SECTION.contact-section-heading-h4' | translate"></h2>
            <p>{{ 'CONTACT-SECTION.contact-section-p-tag' | translate }}</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>{{ 'CONTACT-SECTION.contact-section-form-field1' | translate }}</label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>{{ 'CONTACT-SECTION.contact-section-form-field2' | translate }}</label>
                                    <input type="email" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>{{ 'CONTACT-SECTION.contact-section-form-field3' | translate }}</label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>{{ 'CONTACT-SECTION.contact-section-form-field4' | translate }}</label>
                                    <textarea class="form-control" cols="30" rows="4"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">{{ 'CONTACT-SECTION.contact-section-form-button' | translate }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">                
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="fas fa-map-marker-alt"></i> <span>{{ 'CONTACT-SECTION.contact-section-icon-one' | translate }}:</span>{{ 'CONTACT-SECTION.contact-section-icon-one-text' | translate }}</li>
                                <li><i class="far fa-envelope"></i> <a href="mailto:hello@xavro.com"><span>{{ 'CONTACT-SECTION.contact-section-icon-two' | translate }}:</span>{{ 'CONTACT-SECTION.contact-section-icon-two-text' | translate }}</a></li>
                                <li><i class="fas fa-phone-alt"></i> <a href="tel:1244122445515"><span>{{ 'CONTACT-SECTION.contact-section-icon-three' | translate }}:</span>{{ 'CONTACT-SECTION.contact-section-icon-three-text' | translate }}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <iframe width="100%" height="300" frameborder="0" style="border:0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3012.377965066017!2d29.097269015412973!3d40.97320377930437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6de7ce3b9107a2f!2sMaksellent+Say%C4%B1m%2C+Bili%C5%9Fim+ve+Saha+Hizmetleri!5e0!3m2!1sen!2str!4v1556547380402!5m2!1sen!2str" allowfullscreen>
                </iframe>
            </div>
        </div>
    </div>
</section>