<nav class="navbar navbar-expand-lg navbar-light bg-light header-sticky">
    <div [ngClass]="selectedLanguage.id!='de' ? 'container' : 'container-fluid'">
        <a class="navbar-brand" routerLink="/">
            <img [src]="logoPath">
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><a class="nav-link active" href="#home">{{ 'NAV_BAR.nav-item-link-1-ul-li-a' | translate }}</a></li>
                <li class="nav-item"><a class="nav-link" href="#about">{{ 'NAV_BAR.nav-item-link-2-ul-li-a' | translate }}</a></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#team">Team</a></li> -->
                <li class="nav-item"><a class="nav-link" href="#services">{{ 'NAV_BAR.nav-item-link-3-ul-li-a' | translate }}</a></li>
                <li class="nav-item"><a class="nav-link" href="#why-we-different">{{ 'NAV_BAR.nav-item-link-4-ul-li-a' | translate }}</a></li>
                <li class="nav-item"><a class="nav-link" href="#pricing">{{ 'NAV_BAR.nav-item-link-5-ul-li-a' | translate }}</a></li>
                <li class="nav-item"><a class="nav-link" href="#blog">{{ 'NAV_BAR.nav-item-link-6-ul-li-a' | translate }}</a></li>
                <li class="nav-item"><a class="nav-link" href="#contact">{{ 'NAV_BAR.nav-item-link-7-ul-li-a' | translate }}</a></li>
                <!-- <div class="navbar-nav ml-4">
                    <ul class="selectpicker nav-item dropdown">
                        <li class="nav-link dropdown-toggle" id="dropdown09" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img class="flag mr-8" [src]="'assets/icons/flags/'+ this.selectedLanguage.flag+'.png'">
                            <span> {{this.selectedLanguage.title}}</span>
                        </li>
                        <div class="dropdown-menu" aria-labelledby="dropdown09">
                            <li class="dropdown-item" *ngFor="let lang of languages" (click)="switchLang(lang)">
                                <img class="flag mr-8" [src]="'assets/icons/flags/'+ lang.flag+'.png'">
                                <span> {{lang.title}}</span>
                            </li>
                        </div>
                    </ul>
                </div> -->
            </ul>

            <!-- <div class="navbar-nav ml-4">
            <li class="nav-item dropdown">
                <select class="selectpicker langBtn width" id="id" data-width="" (change)="switchLang($event)">
                    <option value="en" data-content='<span class="flag-icon flag-icon-us"></span> English' (click)="switchLang('en')">English</option>
                    <option value="de" data-content='<span class="flag-icon flag-icon-de"></span> German' (click)="switchLang('de')">German</option>
                    <option value="tr" data-content='<span class="flag-icon flag-icon-tr"></span> Turkish' (click)="switchLang('tr')">Turkish</option>
                </select>
            </li>
            </div> -->

            <div class="navbar-nav ml-auto">
                <ul class="selectpicker nav-item dropdown">
                    <li class="nav-link dropdown-toggle" id="dropdown09" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img class="flag mr-8" [src]="'assets/icons/flags/'+ this.selectedLanguage.flag+'.png'">
                        <span> {{this.selectedLanguage.title}}</span>
                    </li>
                    <div class="dropdown-menu" aria-labelledby="dropdown09">
                        <li class="dropdown-item" *ngFor="let lang of languages" (click)="switchLang(lang)">
                            <img class="flag mr-8" [src]="'assets/icons/flags/'+ lang.flag+'.png'">
                            <span> {{lang.title}}</span>
                        </li>
                    </div>
                </ul>
            </div>

        </div>
    </div>

</nav>