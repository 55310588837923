<section class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2 [innerHTML]="'SECTION-COUNTINGSERVICES.fourth-section-heading-h2' | translate"></h2>
            <p>{{ 'SECTION-COUNTINGSERVICES.fourth-section-p-tag' | translate }}</p>
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-users"></i>
                    <h3>{{ 'SECTION-COUNTINGSERVICES.fourth-section-icon-heading-h3-one' | translate }}</h3>
                    <p>{{ 'SECTION-COUNTINGSERVICES.fourth-section-icon-heading-p-one' | translate }}</p>
                    <span>1</span>
                    <div class="card-border"></div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-lightbulb"></i>
                    <h3>{{ 'SECTION-COUNTINGSERVICES.fourth-section-icon-heading-h3-two' | translate }}</h3>
                    <p>{{ 'SECTION-COUNTINGSERVICES.fourth-section-icon-heading-p-two' | translate }}</p>
                    <span>2</span>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-tag"></i>
                    <h3>{{ 'SECTION-COUNTINGSERVICES.fourth-section-icon-heading-h3-three' | translate }}</h3>
                    <p>{{ 'SECTION-COUNTINGSERVICES.fourth-section-icon-heading-p-three' | translate }}</p>
                    <span>3</span>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-hand-scissors"></i>
                    <h3>{{ 'SECTION-COUNTINGSERVICES.fourth-section-icon-heading-h3-four' | translate }}</h3>
                    <p>{{ 'SECTION-COUNTINGSERVICES.fourth-section-icon-heading-p-four' | translate }}</p>
                    <span>4</span>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fab fa-linode"></i>
                        <h3>{{ 'SECTION-COUNTINGSERVICES.fourth-section-icon-heading-h3-five' | translate }}</h3>
                        <p>{{ 'SECTION-COUNTINGSERVICES.fourth-section-icon-heading-p-five' | translate }}</p>
                    <span>5</span>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-life-ring"></i>
                    <h3>{{ 'SECTION-COUNTINGSERVICES.fourth-section-icon-heading-h3-six' | translate }}</h3>
                    <p>{{ 'SECTION-COUNTINGSERVICES.fourth-section-icon-heading-p-six' | translate }}</p>
                    <span>6</span>
                </div>
            </div>
        </div>
    </div>
</section>