<footer class="footer-area">
    <div class="container">
        <h3><a routerLink="/"><img src="../../../../assets/img/site/logo.png" loading="lazy"></a></h3>
        <ul>
            <li><a href="#" class="fab fa-facebook-f"></a></li>
            <li><a href="#" class="fab fa-twitter"></a></li>
            <li><a href="#" class="fab fa-linkedin-in"></a></li>
            <li><a href="#" class="fab fa-instagram"></a></li>
            <li><a href="#" class="fab fa-skype"></a></li>
        </ul>
        <p>Copyright <i class="far fa-copyright"></i>2020 All Rights Reserved.</p>
    </div>
</footer>

<div class="go-top"><i class="fas fa-chevron-up"></i></div>
<!-- //<app-demo-sidebar></app-demo-sidebar> -->