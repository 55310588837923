import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-why-we-different',
  templateUrl: './why-we-different.component.html',
  styleUrls: ['./why-we-different.component.scss']
})
export class WhyWeDifferentComponent implements OnInit {

  constructor(private translate: TranslateService) {
       
    }

  ngOnInit() {
  }

}
