<section class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 [innerHTML]="'SECTION-FAQ.faq-title-section-heading-h2' | translate"></h2>
            <p>{{ 'SECTION-FAQ.faq-title-section-p' | translate }}</p>
        </div>
       
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                <h5 class="mb-0">
                                    {{ 'SECTION-FAQ.faq-accordion-heading-1' | translate }} <i class="fas fa-chevron-down"></i>
                                </h5>
                            </a>
                        </div>

                        <div id="collapseOne" class="collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                            <div class="card-body">
                             <p>{{ 'SECTION-FAQ.faq-accordion-body-1' | translate }}</p>
                            <p>{{ 'SECTION-FAQ.faq-accordion-body-1-one' | translate }}</p>               
                            <p>{{ 'SECTION-FAQ.faq-accordion-body-1-two' | translate }}</p>
                            <p>{{ 'SECTION-FAQ.faq-accordion-body-1-three' | translate }}</p>
                            <p>{{ 'SECTION-FAQ.faq-accordion-body-1-four' | translate }}</p>
                            <p>{{ 'SECTION-FAQ.faq-accordion-body-1-five' | translate }}</p>
                            <p>{{ 'SECTION-FAQ.faq-accordion-body-1-six' | translate }}</p>
                            <p>{{ 'SECTION-FAQ.faq-accordion-body-1-seven' | translate }}</p>
                            <p>{{ 'SECTION-FAQ.faq-accordion-body-1-eight' | translate }}</p>
                            <p>{{ 'SECTION-FAQ.faq-accordion-body-1-nine' | translate }}</p>
                            <p>{{ 'SECTION-FAQ.faq-accordion-body-1-ten' | translate }}</p>
                        </div>
                        </div>
                    </div>

                    <!-- <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <h5 class="mb-0">
                                    What are the different types of undergraduate degrees? <i class="fas fa-chevron-down"></i>
                                </h5>
                            </a>
                        </div>

                        <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionEx">
                            <div class="card-body">
                                Associate: a two-year program that either leads to a specific vocation or transitions to a bachelor program. Bachelor: a four or five-year program where students earn credits in a wide variety of courses.
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingThree">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <h5 class="mb-0">
                                    What are the different types of graduate degrees? <i class="fas fa-chevron-down"></i>
                                </h5>
                            </a>
                        </div>

                        <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordionEx">
                            <div class="card-body">
                                Masters: two-year degree providing additional specialization. Doctorate: five to eight-year program certifying the student as a trained research scholar and/or professor.
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <h5 class="mb-0">
                                    Can you work while studying in the United States? <i class="fas fa-chevron-down"></i>
                                </h5>
                            </a>
                        </div>

                        <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordionEx">
                            <div class="card-body">
                                With permission of the International Student Office, international students may work on campus up to 20 hours/week their first year and can apply to work off-campus in subsequent years.
                            </div>
                        </div>
                    </div>

                    <div class="card mb-0">
                        <div class="card-header" role="tab" id="headingFive">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <h5 class="mb-0">
                                    What is distance education? <i class="fas fa-chevron-down"></i>
                                </h5>
                            </a>
                        </div>

                        <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordionEx">
                            <div class="card-body">
                                Distance education occurs when a student and an instructor are in different places. Learning occurs by mail, telephone, internet, or by other means.
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            
            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-img.jpg" alt="image" loading="lazy">
                </div>
            </div>
        </div>
    </div>
</section>