<section id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
                <h2 [innerHTML]="'SECTION-ABOUT.second-section-heading-h2' | translate"></h2>
                <p>{{ 'SECTION-ABOUT.second-section-p-tag' | translate }}</p>
        </div>
        
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2 [innerHTML]="'SECTION-ABOUT.second-section-second-heading-h2' | translate"></h2>
                        <p>{{ 'SECTION-ABOUT.second-section-second-p-tag' | translate }}</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>{{ 'SECTION-ABOUT.second-section-ul-li-1' | translate }}</li>
                        <li><i class="fas fa-check"></i>{{ 'SECTION-ABOUT.second-section-ul-li-2' | translate }}</li>
                        <li><i class="fas fa-check"></i>{{ 'SECTION-ABOUT.second-section-ul-li-3' | translate }}</li>
                        <li><i class="fas fa-check"></i>{{ 'SECTION-ABOUT.second-section-ul-li-4' | translate }}</li>
                        <li><i class="fas fa-check"></i>{{ 'SECTION-ABOUT.second-section-ul-li-5' | translate }}</li>
                        <li><i class="fas fa-check"></i>{{ 'SECTION-ABOUT.second-section-ul-li-6' | translate }}</li>
                    </ul>
                    <p>{{ 'SECTION-ABOUT.second-section--third-p-tag' | translate }}</p>
                </div>
            </div>
            
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/about.jpg" alt="about" loading="lazy">
                    
                    <div class="video-btn">
                        <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="popup-youtube"><i class="fas fa-play"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>