<section class="cta-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h4>{{ 'SECTION-ESERVICES.fifth-section-heading-h4' | translate }}</h4>
                <h2>{{ 'SECTION-ESERVICES.fifth-section-heading-h2' | translate }}</h2>
                <p>{{ 'SECTION-ESERVICES.fifth-section-p-tag' | translate }}</p>
                <a href="#contact" class="btn btn-primary">{{ 'SECTION-ESERVICES.fifth-section-button-one' | translate }}</a>
                <a href="#work" class="btn btn-primary view-work">{{ 'SECTION-ESERVICES.fifth-section-button-two' | translate }}</a>
            </div>
        </div>
    </div>
</section>