<section id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2 [innerHTML]="'SECTION-FIELDSERVICES.tenth-section-heading-h2' | translate"></h2>
            <p>{{ 'SECTION-FIELDSERVICES.tenth-section-p-tag' | translate }}</p>
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-pencil-alt"></i>
                    </div>
                    <h3>{{ 'SECTION-FIELDSERVICES.tenth-section-heading-h3-one' | translate }}</h3>
                    <p>{{ 'SECTION-FIELDSERVICES.tenth-section-heading-p-one' | translate }}</p>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fab fa-linode"></i>
                    </div>
                    <h3>{{ 'SECTION-FIELDSERVICES.tenth-section-heading-h3-two' | translate }}</h3>
                    <p>{{ 'SECTION-FIELDSERVICES.tenth-section-heading-p-two' | translate }}</p>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-desktop"></i>
                    </div>
                    <h3>{{ 'SECTION-FIELDSERVICES.tenth-section-heading-h3-three' | translate }}</h3>
                    <p>{{ 'SECTION-FIELDSERVICES.tenth-section-heading-p-three' | translate }}</p>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-chart-line"></i>
                    </div>
                <h3>{{ 'SECTION-FIELDSERVICES.tenth-section-heading-h3-four' | translate }}</h3>
                <p>{{ 'SECTION-FIELDSERVICES.tenth-section-heading-p-four' | translate }}</p>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-anchor"></i>
                    </div>
<h3>{{ 'SECTION-FIELDSERVICES.tenth-section-heading-h3-five' | translate }}</h3>
<p>{{ 'SECTION-FIELDSERVICES.tenth-section-heading-p-five' | translate }}</p>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-headphones"></i>
                    </div>
                <h3>{{ 'SECTION-FIELDSERVICES.tenth-section-heading-h3-six' | translate }}</h3>
                <p>{{ 'SECTION-FIELDSERVICES.tenth-section-heading-p-six' | translate }}</p>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-mobile-alt"></i>
                    </div>
                <h3>{{ 'SECTION-FIELDSERVICES.tenth-section-heading-h3-seven' | translate }}</h3>
                <p>{{ 'SECTION-FIELDSERVICES.tenth-section-heading-p-seven' | translate }}</p>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-camera"></i>
                    </div>
                    <h3>{{ 'SECTION-FIELDSERVICES.tenth-section-heading-h3-eight' | translate }}</h3>
                    <p>{{ 'SECTION-FIELDSERVICES.tenth-section-heading-p-eight' | translate }}</p>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-heartbeat"></i>
                    </div>
                    <h3>{{ 'SECTION-FIELDSERVICES.tenth-section-heading-h3-nine' | translate }}</h3>
                    <p>{{ 'SECTION-FIELDSERVICES.tenth-section-heading-p-nine' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</section>