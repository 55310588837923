import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
declare var $: any;
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import { Title } from "@angular/platform-browser";

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
    @ViewChild("selectpicker") selectPicker: ElementRef;

    languages: any;
    selectedLanguage: any;
    logoPath;
    constructor(private titleService: Title,private translate: TranslateService) {
        this.languages = [
            {
                id: "en",
                title: "English",
                flag: "us",
            },
            {
                id: "tr",
                title: "Turkish",
                flag: "tr",
            },
            {
                id: "de",
                title: "German",
                flag: "de",
            },
        ];

        translate.addLangs(["en", "de", "tr"]);
        translate.setDefaultLang("en");

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
            id: this.translate.getBrowserLang(),
        });

        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : "en");
        this.logoPath = `assets/img/site/Maksellent_Logo_${this.selectedLanguage.flag}_white.png`;
    }

    switchLang(lang: any) {
        this.selectedLanguage = lang;
        this.translate.use(this.selectedLanguage.id);
        this.getTitle();
        
    }
    getTitle(){
         this.translate.get("INDEX_FILE.index-title").subscribe((data) => {
             console.log(data);
             this.titleService.setTitle(data);
         });
        this.logoPath = `assets/img/site/Maksellent_Logo_${this.selectedLanguage.flag}_white.png`;
    }

    ngOnInit() {
        $(".selectpicker").selectpicker();
        window.addEventListener("scroll", this.scroll, true); 
        this.getTitle();
    }
    ngAfterViewInit() {}

    scroll = () => {
        const scrollPos = document.documentElement.scrollTop;
        if (scrollPos > 50) {
              this.logoPath = `assets/img/site/Maksellent_Logo_${this.selectedLanguage.flag}_black.png`;
        } else {
            this.logoPath = `assets/img/site/Maksellent_Logo_${this.selectedLanguage.flag}_white.png`;
        }
    };
}
