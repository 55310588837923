import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-services-two',
  templateUrl: './services-two.component.html',
  styleUrls: ['./services-two.component.scss']
})
export class ServicesTwoComponent implements OnInit {

  constructor(private translate: TranslateService) {
     
    }


  ngOnInit() {
  }

}
