<section class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/about-strategy.jpg" alt="image" loading="lazy">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h4 [innerHTML]="'SECTION-SERVICES.third-section-heading-h4' | translate"></h4>
                        <h2 [innerHTML]="'SECTION-SERVICES.third-section-heading-h2' | translate"></h2>
                        <p>{{ 'SECTION-SERVICES.third-section-p-tag' | translate }}</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fa fa-check"></i>{{ 'SECTION-SERVICES.third-section-ul-li-1' | translate }}</li>
                        <li><i class="fa fa-check"></i>{{ 'SECTION-SERVICES.third-section-ul-li-2' | translate }}</li>
                        <li><i class="fa fa-check"></i>{{ 'SECTION-SERVICES.third-section-ul-li-3' | translate }}</li>
                        <li><i class="fa fa-check"></i>{{ 'SECTION-SERVICES.third-section-ul-li-4' | translate }}</li>
                        <li><i class="fa fa-check"></i>{{ 'SECTION-SERVICES.third-section-ul-li-5' | translate }}</li>
                        <li><i class="fa fa-check"></i>{{ 'SECTION-SERVICES.third-section-ul-li-6' | translate }}</li>
                    </ul>
                    <p>{{ 'SECTION-SERVICES.third-section-second-p' | translate }}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="image" loading="lazy"></div>
</section>